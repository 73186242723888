import request from '@/utils/request'

const fapiaoApi = {
  trade_fapiao_list: '/trade/fapiao/',
  trade_fapiao_create: '/trade/fapiao/',
  trade_fapiao_update: '/trade/fapiao/',
  trade_fapiao_delete: '/trade/fapiao/',
  trade_fapiao_archived: '/trade/fapiao_archived/'
}

/**
 * 列表
 */
export function fapiao_list (parameter) {
  return request({
    url: fapiaoApi.trade_fapiao_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function fapiao_create (parameter) {
  return request({
    url: fapiaoApi.trade_fapiao_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function fapiao_update (parameter, corporation_id) {
  return request({
    url: fapiaoApi.trade_fapiao_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function fapiao_delete (corporation_id) {
  return request({
    url: fapiaoApi.trade_fapiao_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function fapiao_archived (parameter) {
  return request({
    url: fapiaoApi.trade_fapiao_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
