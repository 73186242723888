var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建发票详情","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]),expression:"['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"50%"},attrs:{"precision":2}})],1),_c('a-form-item',{attrs:{"label":"单价"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['unit_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['unit_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"50%"},attrs:{"precision":2}})],1),_c('a-form-item',{attrs:{"label":"货币"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'currency',
                {
                  rules: [
                    { required: true, message: '请选择' }
                  ],
                },
              ]),expression:"[\n                'currency',\n                {\n                  rules: [\n                    { required: true, message: '请选择' }\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"选择","show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption}},_vm._l((this.currency),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(_vm._s(val))])}),1)],1),_c('a-form-item',{attrs:{"label":"单位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'unit',
                {
                  rules: [
                    { required: true, message: '请选择' }
                  ],
                },
              ]),expression:"[\n                'unit',\n                {\n                  rules: [\n                    { required: true, message: '请选择' }\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"选择","show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption}},_vm._l((this.unit),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(_vm._s(val))])}),1)],1),_c('a-form-item',{attrs:{"label":"税率（%）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"50%"},attrs:{"precision":0}})],1),_c('a-form-item',{attrs:{"label":"税额"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['total_tax', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['total_tax', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"50%"},attrs:{"precision":2}})],1),_c('a-form-item',{attrs:{"label":"含税金额"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['total_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['total_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"50%"},attrs:{"precision":2}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }