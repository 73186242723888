<template>
  <a-modal
    title="新建发票详情"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="数量">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="单价">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['unit_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="货币" >
              <a-select
                v-decorator="[
                  'currency',
                  {
                    rules: [
                      { required: true, message: '请选择' }
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.currency">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="单位" >
              <a-select
                v-decorator="[
                  'unit',
                  {
                    rules: [
                      { required: true, message: '请选择' }
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.unit">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="税率（%）">
              <a-input-number :precision="0" style="width: 50%" v-decorator="['rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="税额">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['total_tax', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="含税金额">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['total_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import moment from 'moment'
import pick from 'lodash.pick'
import { project_list } from '@/api/trade_project'
import { client_list } from '@/api/trade_client'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['id', 'name', 'contact', 'phone', 'mail']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.projectSearch = debounce(this.handleProjectSearch, 800)
    this.clientSearch = debounce(this.handleClientSearch, 800)
    return {
      form: this.$form.createForm(this),
      projectList: [],
      clientList: [],
      currency: this.$Dictionaries.currency,
      unit: this.$Dictionaries.unit,
      method: this.$Dictionaries.trade_payment_method
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleProjectSearch(undefined)
    this.handleClientSearch(undefined)
  },
  methods: {
    moment,
    handleProjectSearch (value) {
      project_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.projectList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleProjectChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleProjectSearch(value)
      }
    },
    handleClientSearch (value) {
      client_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.clientList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleClientChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleClientSearch(value)
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    payTimeOK (value) {
      console.log(value.format('YYYY-MM-DD HH:mm:ss'))
    }
  }
}
</script>
